<script setup lang="ts">
import { ArrowLongRightIcon } from '@heroicons/vue/24/outline';
import {
  sanityTopicToTopicPreview,
  type PreviewableSanityTopic,
} from '~/integrations/resources/sanityTopicToTopicPreview';
import { appConfig } from '~/config/appConfig';

const skeletonFeaturedTopicLimit = 3;
const featuredTopicsQuery = groq`
  * [_type == 'topic' && isFeatured] | order(_updatedAt desc) {
    featuredImage,
    'id': _id,
    'name': coalesce(localizedFields[$localeCode].name, localizedFields[$defaultLocaleCode].name),
    'slug': slug.current,
  }
`;

const { locale, t } = useI18n();
const localePath = useLocalePath();

const featuredTopicsQueryResult = await useLazySanityQuery<PreviewableSanityTopic[]>(featuredTopicsQuery, {
  localeCode: locale,
  defaultLocaleCode: appConfig.defaultLocaleCode,
});

const featuredTopicPreviews = computed(() => {
  if (featuredTopicsQueryResult.status.value !== 'success') {
    return [];
  }

  return (featuredTopicsQueryResult.data.value || []).map((topic) => {
    return sanityTopicToTopicPreview(topic);
  });
});
</script>

<template>
  <div>
    <h2 class="h2">{{ t('pages.home.body.featuredTopics') }}</h2>
    <div
      v-if="featuredTopicPreviews.length"
      class="grid grid-flow-row auto-rows-fr grid-cols-2 gap-2 pb-2 pt-3 sm:grid-cols-4 md:grid-cols-1 lg:pt-0"
    >
      <TopicCard
        v-for="(topicPreview, index) in featuredTopicPreviews"
        :key="topicPreview.slug"
        :index="index"
        is-small
        :topic-preview="topicPreview"
        class="hidden sm:flex"
      />
      <!-- Image-less, clickable topic chip for very small screens -->
      <NuxtLink
        v-for="topicPreview in featuredTopicPreviews"
        :key="topicPreview.slug"
        :to="
          localePath({
            path: `/topics/${topicPreview.idSlug}/${topicPreview.slug}`,
          })
        "
        class="flex items-center justify-center rounded-md bg-cawstBlue p-4 text-center text-base font-medium text-white hover:bg-cawstBlue-dark hover:text-white hover:no-underline sm:hidden"
      >
        {{ topicPreview.name }}
      </NuxtLink>
    </div>
    <div
      v-else
      class="grid animate-pulse grid-flow-row auto-rows-fr grid-cols-2 gap-2 pb-2 pt-3 sm:grid-cols-4 md:grid-cols-1 lg:pt-0"
    >
      <div v-for="index in skeletonFeaturedTopicLimit" :key="index" class="hidden h-32 w-full bg-grey-light sm:flex" />
      <!-- Image-less, clickable topic chip for very small screens -->
      <div
        v-for="index in skeletonFeaturedTopicLimit"
        :key="index"
        class="h-32 w-full rounded-md bg-cawstBlue sm:hidden"
      />
    </div>
    <NuxtLink :to="localePath('/explore')" class="btn btn-text group justify-start">
      {{ t('pages.home.body.browseTopics') }}
      <ArrowLongRightIcon class="ml-1 h-6 w-5 transform stroke-2 duration-300 group-hover:translate-x-1" />
    </NuxtLink>
  </div>
</template>
