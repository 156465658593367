<script setup lang="ts">
import { appConfig } from '~/config/appConfig';

const skeletonFeaturedResourceLikeLimit = appConfig.featuredResourceLikeLimit;

const { t } = useI18n();
const { resourcePreviews } = await useResourcePreviews({
  limit: appConfig.featuredResourceLikeLimit,
  featured: true,
  lazy: true,
});
</script>

<template>
  <div v-if="resourcePreviews.length">
    <h2 class="h2 -mb-1">{{ t('pages.home.body.featuredResources') }}</h2>
    <div class="flex flex-col divide-y">
      <ResourceListCard
        v-for="resourcePreview in resourcePreviews"
        :key="resourcePreview.title"
        :resource-preview="resourcePreview"
      />
    </div>
  </div>
  <div v-else>
    <h2 class="h2 -mb-1">{{ t('pages.home.body.featuredResources') }}</h2>
    <div class="flex flex-col divide-y">
      <SkeletonResourceListCard v-for="index of skeletonFeaturedResourceLikeLimit" :key="index" />
    </div>
  </div>
</template>
