import {
  sanityCollectionToResourcePreview,
  type PreviewableSanityCollection,
} from '~/integrations/resources/sanityCollectionToResourcePreview';
import {
  sanityResourceToResourcePreview,
  type PreviewableSanityResource,
} from '~/integrations/resources/sanityResourceToResourcePreview';
import type { ResourcePreview } from '~/integrations/resources/types';

interface UseResourcePreviewsOptions {
  featured?: boolean | Ref<boolean>;
  limit?: number | Ref<number>;
  lazy?: boolean | Ref<boolean>;
}

function isSanityCollection(
  item: PreviewableSanityResource | PreviewableSanityCollection
): item is PreviewableSanityCollection {
  return item._type === 'resourceCollection';
}

export async function useResourcePreviews(options: UseResourcePreviewsOptions = {}): Promise<{
  resourcePreviews: Ref<ResourcePreview[]>;
}> {
  const { limit = 10, featured = false, lazy = false } = options;

  const featuredFilter = computed(() => (toValue(featured) ? '&& isFeatured' : ''));

  const contentQuery = computed(() => {
    return groq`
    * [_type in ['resource', 'resourceCollection'] && isUnlisted != true ${featuredFilter.value}] | order(_updatedAt desc)[0..$limit] {
      _type,
      'id': _id,
      'excerpt': coalesce(localizedFields[$localeCode].excerpt, localizedFields[$defaultLocaleCode].excerpt),
      'slug': slug.current,
      thumbnail,
      'title': coalesce(localizedFields[$localeCode].title, localizedFields[$defaultLocaleCode].title),
      _type == 'resource' => {
        'resourceTypeSlug': type,
        'assets': assets[] {
          'localeCode': language,
          publicationDate,
        },
      },
      _type == 'resourceCollection' => {
        'resources': resources[]-> {
          'assets': assets[] {
            'localeCode': language,
            publicationDate,
          },
        },
      },
    }
  `;
  });

  const { locale, defaultLocale, t } = useI18n();

  const queryResults = await useSanityQuery<Array<PreviewableSanityCollection | PreviewableSanityResource>>(
    contentQuery.value,
    {
      localeCode: locale,
      defaultLocaleCode: defaultLocale,
      limit: toValue(limit) - 1,
    },
    {
      lazy: toValue(lazy),
    }
  );

  const localeCodeToName = (localeCode: string): string => t(`language.${localeCode}`);
  const resourcePreviews = computed(() => {
    if (queryResults.status.value !== 'success') {
      return [];
    }

    return (queryResults.data.value || [])
      .map((item) => {
        if (isSanityCollection(item)) {
          return sanityCollectionToResourcePreview(item, {
            localeCodeToName,
          });
        }

        return sanityResourceToResourcePreview(item, {
          ignoreNoLanguage: true,
          localeCodeToName,
        });
      })
      .sort((a, b) => b.publicationDate.localeCompare(a.publicationDate));
  });

  return {
    resourcePreviews,
  };
}
