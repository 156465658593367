<script setup lang="ts">
import { appConfig } from '~/config/appConfig';

const skeletonRecentResourceLikeLimit = appConfig.recentResourceLikeLimit;
const { resourcePreviews } = await useResourcePreviews({
  limit: appConfig.recentResourceLikeLimit,
});

const { t } = useI18n();
</script>

<template>
  <div class="recently-added">
    <h2 class="h2--no-border">{{ t('pages.home.body.recent') }}</h2>
    <div
      v-if="resourcePreviews.length"
      class="grid grid-cols-1 gap-6 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:flex lg:justify-between lg:gap-0"
    >
      <ResourceGalleryCard
        v-for="resourcePreview in resourcePreviews"
        :key="resourcePreview.title"
        :resource-preview="resourcePreview"
        class="sm:hide-last md:show-last"
      />
    </div>
    <div
      v-else
      class="grid grid-cols-1 gap-6 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:flex lg:justify-between lg:gap-0"
    >
      <SkeletonResourceGalleryCard
        v-for="index of skeletonRecentResourceLikeLimit"
        :key="index"
        class="sm:hide-last md:show-last"
      />
    </div>
  </div>
</template>
