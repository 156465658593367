<script setup lang="ts">
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline';
definePageMeta({
  layout: 'transparent',
});

const { $viewport } = useNuxtApp();
const { t } = useI18n();
const localePath = useLocalePath();
const router = useRouter();

const searchTerms = ref('');

function submitSearch() {
  const sanitizedSearchTerms = removeExtraWhitespace(searchTerms.value);
  router.push(
    localePath({
      path: '/search',
      query: { query: sanitizedSearchTerms },
    })
  );
}
</script>

<template>
  <div class="home-page">
    <!-- Hero section -->
    <div class="bg-grey-light py-16 md:py-20 lg:py-24">
      <div class="container grid grid-cols-1 md:grid-cols-2 md:gap-12">
        <TransitionRoot
          appear
          show
          enter="transform transition ease-in-out duration-700"
          enter-from="opacity-0 -translate-x-2"
          enter-to="opacity-100 translate-x-0"
        >
          <h1 class="h1">
            {{ t('pages.home.header.title.plainText') }}
            <span class="highlighted-text">{{ t('pages.home.header.title.highlightedText') }}</span>
          </h1>
        </TransitionRoot>
        <TransitionRoot
          appear
          show
          enter="transform transition ease-in-out duration-700"
          enter-from="opacity-0 translate-x-2"
          enter-to="opacity-100 translate-x-0"
        >
          <div>
            <p class="mb-8 text-grey-darkest">{{ t('pages.home.header.details') }}</p>
            <div class="flex">
              <NuxtLink :to="localePath({ path: '/explore' })" class="btn btn-ghost btn-xlarge">
                {{ t('pages.home.exploreTopics') }}
              </NuxtLink>
            </div>
          </div>
        </TransitionRoot>
      </div>
    </div>
    <!-- Navy search CTA section -->
    <div class="page-subsection bg-cawstNavy text-center">
      <div class="container grid lg:grid-cols-4">
        <div class="col-span-2 -mt-2 lg:col-start-2">
          <h2 class="h3 pb-3 pt-0 text-white text-opacity-80">{{ t('pages.home.searchBar.title') }}</h2>
          <form @submit.prevent="submitSearch">
            <div class="flex items-center lg:px-6 xl:px-0">
              <label for="search" class="sr-only">{{ t('navbar.srText.icons.search') }}</label>
              <div class="relative flex-grow">
                <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-5">
                  <MagnifyingGlassIcon class="h-5 w-5 stroke-2 text-gray-500" />
                </div>
                <input
                  id="search"
                  v-model="searchTerms"
                  name="search"
                  class="block w-full rounded-l-full border border-white bg-white py-3 pl-14 pr-3 placeholder-gray-500 focus:border-cawstBlue focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-cawstBlue"
                  :placeholder="
                    $viewport.isLessThan('sm') ? t('searchBar.placeholder.mobile') : t('searchBar.placeholder.desktop')
                  "
                  type="search"
                />
              </div>
              <button
                class="btn btn-xlarge rounded-r-full border-cawstYellow bg-cawstYellow py-3 text-grey-darkest shadow-sm hover:bg-cawstYellow-dark"
              >
                {{ t('searchBar.button') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Recently added section -->
    <TransitionRoot
      appear
      show
      enter="transform transition ease-in-out duration-700"
      enter-from="opacity-0 translate-y-2"
      enter-to="opacity-100 translate-y-0"
    >
      <TheRecentlyAddedContentList class="page-subsection container -mb-3" />
    </TransitionRoot>

    <!-- Featured sections -->
    <div class="page-subsection container flex flex-col-reverse gap-16 md:grid md:grid-cols-4 md:gap-12">
      <!-- Featured resources section -->
      <TheFeaturedContentList class="col-span-3 mb-12 md:mb-0" />

      <!-- Featured topics section -->
      <TheFeaturedTopicsSidebar />
    </div>
  </div>
</template>
